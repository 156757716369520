import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSpring, animated } from 'react-spring';

function Step({ currentStep, formData, handleInputChange, handleNextStep, handlePrevStep, handleSubmit, setFormData }) {
    const [buttoncolor, setButtoncolor] = useState(false) // ボタンを次に進める状態にするかどうか
    const [showerror, setShowerror] = useState(false) // エラーメッセージを表示するかどうか
    const [showerrorpref, setShowerrorpref] = useState(false) // 都道府県のエラーメッセージを表示するかどうか
    const [showerrorcity, setShowerrorcity] = useState(false) // 市町村のエラーメッセージを表示するかどうか
    const [showerroryear, setShowerroryear] = useState(false) // 誕生年のエラーメッセージを表示するかどうか
    const [showpostal, setShowpostal] = useState(false) // 都道府県と市町村のボックスを展開するかどうか

    // 郵便番号から都道府県、市町村を取得
    const fetchAddressData = async (code) => {
        // 7桁の場合のみ
        if (currentStep === 3 && code.length === 7) {
        try {
            // 住所を取得
            const response = await axios.get(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${code}`);
            const addressData = response.data.results[0];
            // 都道府県と市町村を設定
            setFormData(prevFormData => ({...prevFormData, prefecture: addressData.address1}))
            setFormData(prevFormData => ({...prevFormData, address: addressData.address2}))
            setFormData(prevFormData => ({...prevFormData, zip: code}))
        } catch (error) {
            console.error('Error fetching address data:', error);
        }
        }
    };
    
    // 入力のときのパンダの位置
    const inputpandaHeight = ['120px','80px','80px','116px','116px','116px']
    // 次へのときのパンダの位置
    const pandaHeight = ['475px','351px','445px','304px','286px','277px']
    
    const prefecturepandaHeight = '288px'

    const citypandaHeight = '345px'

    const postalpandaLeft = '405px'

    // 住所展開時のパンダの位置
    const expandpandaHeight = '412px'

    const yearpandaHeight = '208px'

    const mailpandaHeight = '198px'

    // 年の計算のための日にちデータ
    var today = new Date()

    // エラーメッセージを表示するか、次ステップへ進むか
    const handleErrorMessage = (e) => {
        // 次へボタンが進む状態でない場合はエラーメッセージを表示
        if (!buttoncolor){
            setShowerror(true)
            if (currentStep==3){
                if (formData.prefecture===""){
                    setShowerrorpref(true)
                }
                if (formData.address===""){
                    setShowerrorcity(true)
                }
            }
            if (currentStep==4){
                if (formData.name===""){
                    setShowerrorpref(true)
                }
                if (formData.birthYear===""){
                    setShowerrorcity(true)
                }
                if (formData.birthYear<="1900" || formData.birthYear>today.getFullYear()){
                    setShowerroryear(true)
                }
            }
            
            return
        }
        // 次ステップへ進むか最終ステップの場合はサブミット
        if (currentStep<5){
            handleNextStep()
        } else {
            handleSubmit(e);
        }
    }

    // エラーメッセージを表示するためのステップごとの条件
    useEffect(()=>{
        let status = false
        let status1 = false
        let status2 = false
        let status3 = false
        if (currentStep==0){
            if (formData.qualifications.length!==0){
                status = true
            }
        } else 
        if (currentStep==1){
            if (formData.workStyle!==""){
                status = true
            }
        } else 
        if (currentStep==2){
            if (formData.transferTiming!==""){
                status = true
            }
        } else
        if (currentStep==3){
            if (formData.prefecture!==""){
                status1 = true
            } 
            if (formData.address!==""){
                status2 = true
            }
            if (formData.prefecture!=="" && formData.address!==""){
                status = true
            }
        } else
        if (currentStep==4){
            if (formData.name!==""){
                status1 = true
            }
            if (formData.birthYear!==""){
                status2 = true
            }
            if (formData.birthYear>="1900" && formData.birthYear<=today.getFullYear()){
                status3 = true
            }
            if (formData.name!=="" && formData.birthYear!==""){
                status = true
            }
        } else
        if (currentStep==5){
            if (formData.phoneNumber!==""){
                status = true
            }
        }
        if (status1){
            setShowerrorpref(false)
        }
        if (status2){
            setShowerrorcity(false)
        }
        if (status3){
            setShowerroryear(false)
        }
        if (status){
            setButtoncolor(true)
            setShowerror(false)
            return
        }

        // statusがfalseのときだけ次ステップへ進めるようになる
        setButtoncolor(false)
        setShowerror(false)

    },[formData, currentStep])

    const [top, setTop] = useState('80px')
    const [left, setLeft] = useState('375.6px')

    useEffect(()=>{
        if (currentStep==0 && buttoncolor){
            setLeft('345.6px')
        } else if (currentStep==0 && !buttoncolor){
            setLeft('375.6px')
        } else if (currentStep>0 && buttoncolor){
            setLeft('370.6px')
        } else if (showpostal && (formData.prefecture==='' || formData.address==='')){
            setLeft(postalpandaLeft)
        }

        if (showpostal && currentStep==3 && formData.prefecture===''){
            setTop(prefecturepandaHeight)
        } else if (showpostal && currentStep==3 && formData.address===''){
            setTop(citypandaHeight)
        } else if (buttoncolor && showpostal && currentStep==3){
            setTop(expandpandaHeight)
        } else if (formData.name!=='' && formData.birthYear==='' && currentStep==4){
            setTop(yearpandaHeight)
        } else if (formData.phoneNumber!=='' && formData.email==='' && currentStep==5){
            setTop(mailpandaHeight)
        } else if (buttoncolor && !showpostal){
            setTop(pandaHeight[currentStep])
        } else {
            setTop(inputpandaHeight[currentStep])
        }
    }, [showpostal, currentStep, formData, buttoncolor])

    const styles = useSpring({
        top: top, 
        left: left,
        config: { duration: 0 },
    })

    // パンダの表示
    function PointHand() {
        return (
            <animated.div 
                className='hand' 
                style={{...styles}}
                >
                <img id="pointhand" src="img/hand.png" width="46px" alt="" />
            </animated.div>
        )
    }
  
    const renderStep = () => {
    switch (currentStep) {
        case 0:
            return (
                <>
                {/* <div style={{margin: '1.1rem'}}>
                    <img src="img/step_title_1.png" width="100%" alt=""/>
                </div> */}
                <div id="step1">
                    <h2 id="step1_1" className="square">
                    どの資格をお持ちですか？<span>（複数選択可）</span>
                    </h2>
                    <ul className="button-check">
                    <li className="btn-1">
                        <input
                        id="license-1"
                        type="checkbox"
                        name="qualifications"
                        value="介護福祉士"
                        checked={formData.qualifications.includes('介護福祉士')}
                        onChange={handleInputChange}
                        />
                        <label htmlFor="license-1">介護福祉士</label>
                    </li>
                    <li className="btn-2">
                        <input
                        id="license-2"
                        type="checkbox"
                        name="qualifications"
                        value="初任者研修"
                        checked={formData.qualifications.includes('初任者研修')}
                        onChange={handleInputChange}
                        />
                        <label htmlFor="license-2">初任者研修</label>
                    </li>
                    <li className="btn-3">
                        <input
                        id="license-3"
                        type="checkbox"
                        name="qualifications"
                        value="実務者研修"
                        checked={formData.qualifications.includes('実務者研修')}
                        onChange={handleInputChange}
                        />
                        <label htmlFor="license-3">実務者研修</label>
                    </li>
                    <li className="btn-4">
                        <input
                        id="license-4"
                        type="checkbox"
                        name="qualifications"
                        value="ケアマネージャー"
                        checked={formData.qualifications.includes('ケアマネージャー')}
                        onChange={handleInputChange}
                        />
                        <label htmlFor="license-4">ケアマネージャー</label>
                    </li>
                    <li className="btn-5">
                        <input
                        id="license-5"
                        type="checkbox"
                        name="qualifications"
                        value="無資格・その他"
                        checked={formData.qualifications.includes('無資格・その他')}
                        onChange={handleInputChange}
                        />
                        <label htmlFor="license-5">無資格・その他</label>
                    </li>
                    </ul>
                    <div style={{display: showerror && "block"}} className="error_message_0">資格を選んでください</div>
                </div>
                </>
            );
        case 1:
            return (
                <>
                {/* <div style={{margin: '1.1rem'}}>
                    <img src="img/step_title_2.png" width="100%" alt=""/>
                </div> */}
                <div id="step2">
                    <h2 id="step2_1" className="square">
                    ご希望の働き方は？
                    </h2>
                    <ul className="button-check">
                    <li className="btn-1">
                        <input
                        id="hopework-1"
                        type="radio"
                        name="workStyle"
                        value="常勤(日勤のみ)"
                        checked={formData.workStyle === '常勤(日勤のみ)'}
                        onChange={handleInputChange}
                        />
                        <label htmlFor="hopework-1">常勤(日勤のみ)</label>
                    </li>
                    <li className="btn-2">
                        <input
                        id="hopework-2"
                        type="radio"
                        name="workStyle"
                        value="常勤(夜勤含む)"
                        checked={formData.workStyle === '常勤(夜勤含む)'}
                        onChange={handleInputChange}
                        />
                        <label htmlFor="hopework-2">常勤(夜勤含む)</label>
                    </li>
                    <li className="btn-3">
                        <input
                        id="hopework-3"
                        type="radio"
                        name="workStyle"
                        value="非常勤"
                        checked={formData.workStyle === '非常勤'}
                        onChange={handleInputChange}
                        />
                        <label htmlFor="hopework-3">非常勤</label>
                    </li>
                    <li className="btn-4">
                        <input
                        id="hopework-4"
                        type="radio"
                        name="workStyle"
                        value="こだわらない"
                        checked={formData.workStyle === 'こだわらない'}
                        onChange={handleInputChange}
                        />
                        <label htmlFor="hopework-4">こだわらない</label>
                    </li>
                    </ul>
                    <div style={{display: showerror && "block"}} className="error_message_1">働き方を選んでください</div>
                </div>
                </>
            );
        case 2:
            return (
                <>
                {/* <div style={{margin: '1.1rem'}}>
                    <img src="img/step_title_3.png" width="100%" alt=""/>
                </div> */}
                <div id="step3">
                    <h2 id="step3_1" className="square">
                    いつ頃の転職をご希望ですか？
                    </h2>
                    <ul className="button-check">
                    <li className="btn-1">
                        <input
                        id="season-1"
                        type="radio"
                        name="transferTiming"
                        value="1ヶ月以内"
                        checked={formData.transferTiming === '1ヶ月以内'}
                        onChange={handleInputChange}
                        />
                        <label htmlFor="season-1">1ヶ月以内</label>
                    </li>
                    <li className="btn-2">
                        <input
                        id="season-2"
                        type="radio"
                        name="transferTiming"
                        value="3ヶ月以内"
                        checked={formData.transferTiming === '3ヶ月以内'}
                        onChange={handleInputChange}
                        />
                        <label htmlFor="season-2">3ヶ月以内</label>
                    </li>
                    <li className="btn-3">
                        <input
                        id="season-3"
                        type="radio"
                        name="transferTiming"
                        value="6ヶ月以内"
                        checked={formData.transferTiming === '6ヶ月以内'}
                        onChange={handleInputChange}
                        />
                        <label htmlFor="season-3">6ヶ月以内</label>
                    </li>
                    <li className="btn-4">
                        <input
                        id="season-4"
                        type="radio"
                        name="transferTiming"
                        value="6ヶ月より先"
                        checked={formData.transferTiming === '6ヶ月より先'}
                        onChange={handleInputChange}
                        />
                        <label htmlFor="season-4">6ヶ月より先</label>
                    </li>
                    <li className="btn-5">
                        <input
                        id="season-5"
                        type="radio"
                        name="transferTiming"
                        value="良い求人があればいつでも"
                        checked={formData.transferTiming === '良い求人があればいつでも'}
                        onChange={handleInputChange}
                        />
                        <label htmlFor="season-5">良い求人があればいつでも</label>
                    </li>
                    </ul>
                    <div style={{display: showerror && "block"}} className="error_message_2">転職時期を選んでください</div>
                </div>
                </>
            );
        case 3:
            return (
                <>
                {/* <div style={{margin: '1.1rem'}}>
                    <img src="img/step_title_4.png" width="100%" alt=""/>
                </div> */}
                <div id="step4">
                <p className="private"><span>公開されません</span></p>
                <h2 id="step4_1" className="square">
                    お住まいの郵便番号
                </h2>
                <div className="add_contents">
                    <div className="number">
                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                        <tr>
                        <td>
                            <input
                            data-testid="zipinput"
                            autoFocus
                            type="tel"
                            name="zip"
                            pattern="\d"
                            size="10"
                            maxLength="8"
                            placeholder="1234567"
                            value={formData.zip}
                            onKeyUp={(e) => {
                                const zip = e.target.value;
                                fetchAddressData(zip)
                            }}
                            onChange={handleInputChange}
                            />
                        </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <div data-testid="addressexpand" className='number_no' style={{cursor: 'pointer'}} onClick={()=>setShowpostal(prevstate=>!prevstate)}>
                                    郵便番号がわからない場合はこちら
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} className='comment'>
                            お住い近くのエリアで、
                            <br/>
                            最新の求人情報をお届けします。
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                    <div className="addarea" style={{display: showpostal && "block"}}>
                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                        <tr>
                        <td className="pd">
                            <select
                            data-testid="prefectureselect"
                            name="prefecture"
                            value={formData.prefecture}
                            onChange={handleInputChange}
                            style={{cursor: 'pointer'}}
                            >
                            <option value="">都道府県</option>
                            <option value="北海道">北海道</option>
                            <option value="青森県">青森県</option>
                            <option value="岩手県">岩手県</option>
                            <option value="宮城県">宮城県</option>
                            <option value="秋田県">秋田県</option>
                            <option value="山形県">山形県</option>
                            <option value="福島県">福島県</option>
                            <option value="茨城県">茨城県</option>
                            <option value="栃木県">栃木県</option>
                            <option value="群馬県">群馬県</option>
                            <option value="埼玉県">埼玉県</option>
                            <option value="千葉県">千葉県</option>
                            <option value="東京都">東京都</option>
                            <option value="神奈川県">神奈川県</option>
                            <option value="新潟県">新潟県</option>
                            <option value="富山県">富山県</option>
                            <option value="石川県">石川県</option>
                            <option value="福井県">福井県</option>
                            <option value="山梨県">山梨県</option>
                            <option value="長野県">長野県</option>
                            <option value="岐阜県">岐阜県</option>
                            <option value="静岡県">静岡県</option>
                            <option value="愛知県">愛知県</option>
                            <option value="三重県">三重県</option>
                            <option value="滋賀県">滋賀県</option>
                            <option value="京都府">京都府</option>
                            <option value="大阪府">大阪府</option>
                            <option value="兵庫県">兵庫県</option>
                            <option value="奈良県">奈良県</option>
                            <option value="和歌山県">和歌山県</option>
                            <option value="鳥取県">鳥取県</option>
                            <option value="島根県">島根県</option>
                            <option value="岡山県">岡山県</option>
                            <option value="広島県">広島県</option>
                            <option value="山口県">山口県</option>
                            <option value="徳島県">徳島県</option>
                            <option value="香川県">香川県</option>
                            <option value="愛媛県">愛媛県</option>
                            <option value="高知県">高知県</option>
                            <option value="福岡県">福岡県</option>
                            <option value="佐賀県">佐賀県</option>
                            <option value="長崎県">長崎県</option>
                            <option value="熊本県">熊本県</option>
                            <option value="大分県">大分県</option>
                            <option value="宮崎県">宮崎県</option>
                            <option value="鹿児島県">鹿児島県</option>
                            <option value="沖縄県">沖縄県</option>
                            </select>
                        </td>
                        </tr>
                        <tr>
                        <td>
                            <input
                            data-testid="addressinput"
                            type="text"
                            name="address"
                            value={formData.address}
                            size="18"
                            placeholder="市区町村以下（必須）"
                            onChange={handleInputChange}
                            />
                        </td>
                        </tr>
                        </tbody>
                    </table>
                    <div style={{display: showerrorpref && "block"}} className="error_message_3_1">都道府県を選択してください</div>
                    <div style={{display: showerrorcity && "block"}} className="error_message_3_2">市区町村を入力してください</div>
                    </div>
                </div>
                </div>
                </>
            );
        case 4:
        return (
            <>
            {/* <div style={{margin: '1.1rem'}}>
                <img src="img/step_title_5.png" width="100%" alt=""/>
                </div> */}
            <div id="step5">
            <p className="private"><span>公開されません</span></p>
            <h2 id="step5_1" className="square">
                <span>お名前</span>
            </h2>
            <div className="step_5_contents">
                <div className="block">
                <input
                    autoFocus
                    type="text"
                    name="name"
                    value={formData.name}
                    size="40"
                    maxLength="255"
                    placeholder="例）介護花子"
                    onChange={handleInputChange}
                />
                </div>
                <div style={{display: showerrorpref && "block"}} className="error_message_4_1">氏名を入力してください</div>
            </div>
        
            <h2 id="step5_2" className="square">
                <span>生まれ年</span>
            </h2>
            <div className="step_5_contents">
                <div className="block">
                <input
                    type="tel"
                    name="birthYear"
                    pattern="\d"
                    size="8"
                    maxLength="4"
                    placeholder="2001"
                    value={formData.birthYear}
                    onChange={handleInputChange}
                />
                </div>
                <div style={{display: showerrorcity && "block"}} className="error_message_4_2">誕生年を入力してください</div>
                <div style={{display: showerroryear && "block"}} className="error_message_4_3">西暦（誕生年）が正しくありません</div>
            </div>
            </div>
            </>
        );
        case 5:
        return (
            <>
            {/* <div style={{margin: '1.1rem'}}>
                <img src="img/step_title_6.png" width="100%" alt=""/>
            </div> */}
            <div id="step6">
            <p className="private"><span>公開されません</span></p>
            <h2 id="step6_1" className="square">
                <span className="label">携帯番号</span><span className="required">必須</span>
            </h2>
            <div className="step_6_contents">
                <div className="block">
                <input
                    autoFocus
                    type="tel"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    size="30"
                    maxLength="32"
                    placeholder="例）08012345678"
                    onChange={handleInputChange}
                />
                </div>
                <div style={{display: showerror && "block"}} className="error_message_5">携帯番号を入力してください</div>
            </div>
        
            <h2 id="step6_2" className="square">
                <span className="label">メールアドレス</span><span className="optional">任意</span>
            </h2>
            <div className="step_6_contents">
                <div className="block">
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    size="40"
                    maxLength="255"
                    placeholder="例）メールアドレス"
                    autoComplete="email"
                    onChange={handleInputChange}
                />
                </div>
            </div>
            </div>
            </>
        );
        default:
        return null;
        }
        };
          
    return (
        <div id="container__step" style={{position: 'relative'}}>
            <div className="step-progress">
                {Array.from({ length: 6 }, (_, index) => (
                <div key={index} className={`step-item-container ${index + 1 <= currentStep + 1 ? 'active' :  index == currentStep + 1 ? 'next' : ''}`}>
                <div
                    className={`step-item ${index <= currentStep ? 'active' : index == currentStep + 1 ? 'next' : ''}`}
                >
                    <span className={`step-label large ${index <= currentStep ? 'active' :  index == currentStep + 1 ? 'next' : ''}`}>
                        <span className='text-bold'></span><span>{index + 1}</span>
                    </span>
                </div>
                </div>
                ))}
            </div>
            <div className="content">{renderStep()}</div>
            <div className="actions">
                <ul>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {currentStep > 0 && (
                    <li data-testid="prevbutton" style={{cursor: 'pointer', marginRight: '0.5rem'}} className="previous previous_step_1" onClick={handlePrevStep}>
                        <a role='menuitem' style={{cursor: 'pointer'}}>
                            前へ
                        </a>
                    </li>
                    )}
                    {currentStep < 5 && (
                    <li data-testid="nextbutton" style={{cursor: 'pointer'}} className={!buttoncolor ? "next next_step_0_off" : "next next_step_0"} aria-hidden="false" aria-disabled="false" onClick={handleErrorMessage}>
                        <a role='menuitem' style={{cursor: 'pointer'}}>
                        次へ
                        </a>
                    </li>
                    )}
                    {currentStep === 5 && (
                    <li data-testid="submit" style={{cursor: 'pointer'}} className={!buttoncolor ? "finish finish_step_5_off" : "finish finish_step_5"} aria-hidden="false" aria-disabled="false" onClick={(e)=>{handleErrorMessage(e)}}>
                        <a role='menuitem' id='finish' style={{cursor: 'pointer'}}>
                            <span className='cvtext'>利用規約に同意して</span>
                            <span className='cvtext2'>求人を探しに行く！</span>
                        </a>
                    </li>
                    )}
                </div>
                </ul>
            </div>
            <PointHand/>
        </div>
    );
}
    
export default Step;