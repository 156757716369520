import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/basic_style2.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

const TermsPage = () => {
  useEffect(() => {
    // Yahoo Tag Manager
    const script = document.createElement('script');
    script.src = "https://s.yimg.jp/images/listing/tool/cv/ytag.js";
    script.async = true;
    document.head.appendChild(script);

    window.yjDataLayer = window.yjDataLayer || [];
    function ytag() { window.yjDataLayer.push(arguments); }
    ytag({"type":"ycl_cookie"});

    // Google Tag Manager
    const googleScript = document.createElement('script');
    googleScript.src = "https://www.googletagmanager.com/gtag/js?id=AW-676247113";
    googleScript.async = true;
    document.head.appendChild(googleScript);

    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'AW-676247113');

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(googleScript);
    };
  }, []);

  return (
    <div className="wrap">
      <Header/>
      
      <div id="container">
        <br />
        <h2 style={{textAlign: 'center'}}>利用規約</h2>

        <h3>1.介護ナインとは</h3>
        <p>「介護ナイン」（以下、本サービス）とは、株式会社N.E（以下、当社）が本サイトを通じて提供する求人・求職情報および転職支援、その他関連する各種サービスの総称です。</p>

        <h3>2.ユーザーとは</h3>
        <ul>
          <li>1.ユーザーとは、本サービスへの登録を行った方、かつ当社がこれを承認した方をいいます。</li>
          <li>2.ユーザーは、本サービスへの登録を行った時点で、本規約の内容をすべて了承し同意したものとします。</li>
        </ul>

        <h3>3.提供するサービス</h3>
        <p>本サービスにおいては、以下のサービスの提供を行います。</p>
        <ul>
          <li>1.ユーザーへの求人情報の提供</li>
          <li>2.キャリアアドバイザーによるヒアリング、転職相談への対応（電話、面談等）、ほか転職支援全般</li>
          <li>3.ユーザーの応募から入職までの一連の過程における、求人施設との各種交渉の代行</li>
          <li>4.その他、ユーザーの転職活動に関して適切かつ有益と見込んだサービス全般</li>
        </ul>

        <h3>4.禁止事項</h3>
        <p>本サービスを利用するユーザーは、以下に挙げる行為およびそれに類する行為を行ってはならないものとします。</p>
        <ul>
          <li>1.虚偽の情報およびユーザー本人以外の情報の申告・登録</li>
          <li>2.各種法令および公序良俗に反する行為（そのおそれがある行為を含む）</li>
          <li>3.第三者または当社の権利を侵害する行為（知的所有権、名誉、財産、プライバシー等）</li>
          <li>4.第三者または当社に不利益を与える行為（そのおそれがある行為を含む）</li>
          <li>5.第三者または当社の評価や信頼性を毀損する行為（そのおそれがある行為を含む）</li>
          <li>6.第三者または当社への誹謗中傷</li>
          <li>7.本サービスを利用して得た情報を第三者に漏洩、または私的利用の範囲を超えて使用・開示する行為</li>
          <li>8.本サービスの円滑な運営を妨げる、もしくは運営の目的にそぐわない行為</li>
          <li>9.その他、当社が不適切と判断する行為</li>
        </ul>

        <h3>5.ユーザーの責任</h3>
        <ul>
          <li>1.ユーザーは、自らの意思により、自己の責任にもとづいて本サービスを利用するものとします。</li>
          <li>2.ユーザーは、本サービスの利用に際し、当社に提供した登録情報について一切の責任を負うものとします。</li>
          <li>3.前項の登録情報は、ユーザーの求めに応じ、必要な範囲内で変更、追加、削除できるものとし、常にユーザーが責任をもって利用目的に沿い、正確、最新に保つものとします。</li>
          <li>4.当社は入職後の労働条件について求人施設・企業に確認し、かつユーザーに通知しますが、その通知内容は当該雇用契約の内容を最終的に保障するものではありません。ユーザーは、求人施設・企業に対して労働条件、仕事内容等を直接確認し、自己の責任において雇用契約を結ぶものとします。</li>
          <li>5.ユーザーは、本サービスが転職の確実な成功を保証するものではないことを承諾します。</li>
          <li>6.キャリアアドバイザーとの連絡のやりとりについては電話およびメールおよびSNSで行うことを希望します。</li>
        </ul>

        <h3>6.免責</h3>
        <ul>
          <li>1.当社は、本サービスの利用によりユーザーに生じた一切の損害および不利益（転職不成功その他の理由による精神的苦痛、金銭的負担等）について、責任を負わないものとします。</li>
          <li>2.当社は、第三者の責任により提供される施設・企業情報、広告等の情報、その他ユーザーが本サービスに登録し掲載する情報等に関し、内容の最新性、正確性、完全性、有用性等について保証するものではないものとします。</li>
          <li>3.当社は、ユーザーの本サービスへの登録データが変更・削除されないことを保証するものではありません。</li>
          <li>4.当社は、当社の責によらない天変地異等の事由による本サービスの提供の中断、停止、利用不能、ユーザーの情報の消失その他によってユーザーが被った損害につき、責任を負わないものとします。</li>
          <li>5.当社は本サービスにエラーや不具合、ウィルス被害等が生じないことを保証するものではありません。ユーザーが本サービスを利用したことによるデータの消失、機器の故障もしくは損傷について、当社は責任を負わないものとします。</li>
          <li>6.当社は、本サービスから他のウェブサイトへのリンクまたは他のウェブサイトから本サービスへのリンクが提供されている場合でも、本サービス以外の第三者が提供するウェブサイトおよびサービス、またそこから得られる情報に関して、如何なる理由に基づいても一切責任を負わないものとします。</li>
          <li>7.ユーザーの了解を得た上で、本サービスを通じて求人施設・企業に提出されたユーザーの個人情報の取り扱いは求人施設・企業の責任に基づくものとし、当社は一切責任を負わないものとします。</li>
          <li>8.当社および求人施設・企業の機密に属する事項について、当社は、ユーザーからの質問への回答、その他対応を行う義務を一切負わないものとします。</li>
          <li>9.当社の責任を免責する本規約の条項が消費者契約法等の法令に反することによって無効となる場合など、何らかの理由によって当社が本サービスに関してユーザーに対して損害賠償責任を負うべき場合でも、故意または重過失による場合を除き、当社の賠償責任は、ユーザーに生じた直接かつ通常の損害の範囲に限るものとします。</li>
        </ul>

        <h3>7.サービスおよび利用規約の変更</h3>
        <ul>
          <li>1.当社は本サービスの適切な運営・提供のため、予告なくサービス内容の変更や一時中断を行うことができるものとします。</li>
          <li>2.当社はユーザーの承認なく規約の内容を変更できるものとします。ユーザーは本サービスを利用することにより、規約変更に同意したものとみなします。</li>
          <li>3.ただし個人情報の取扱いについては対象外とします。</li>
        </ul>

        <h2 className="terms_head">個人情報の取り扱いについて</h2>
        <h3>（1）事業者の氏名または名称</h3>
        <p>株式会社N.E</p>

        <h3>（2）個人情報の利用目的</h3>
        <ul>
          <li>・採用ご担当者様向けフォーム：お仕事情報の掲載に関わる連絡及びお取引のため</li>
          <li>・お問い合わせフォーム：お問い合わせいただいた内容に回答するため</li>
        </ul>

        <h3>（3）個人情報の第三者提供について</h3>
        <p>利用者の個人情報について、利用者本人の同意を得ずに当社サービス利用企業などの第三者に開示することは、原則いたしません。提供先・提供情報内容を特定したうえで、利用者の同意を得た場合に限り開示します。但し法令の範囲内で、利用者の個人情報を提供することがあります。</p>

        <h3>（4）個人情報の取扱いの委託について</h3>
        <p>当社は利用目的の達成に必要な範囲内において個人情報の取り扱いの全部または一部を委託する場合があります。なお、個人情報の取り扱いを委託する場合は適切な委託先を選定し、個人情報が安全に管理されるよう適切に監督いたします。</p>

        <h3>（5）個人情報を与えなかった場合に生じる結果</h3>
        <p>個人情報を与えることは任意です。個人情報に関する情報の一部をご提供いただけない場合は、お問い合わせ内容に回答できない可能性があります。</p>

        <h3>（6）開示対象個人情報の開示等および問い合わせ窓口について</h3>
        <p>ご本人からの求めにより、当社が保有する開示対象個人情報に関する開示、利用目的の通知、内容の訂正・追加または削除、利用停止、消去および第三者提供の停止(以下、開示等という)に応じます。</p>

        <h3>（7）本人が容易に認識できない方法による個人情報の取得</h3>
        <p>クッキーやウェブビーコン等を用いるなどして、本人が容易に認識できない方法による個人情報の取得は行っておりません。</p>

        <h3>（8）個人情報の安全管理措置について</h3>
        <p>取得した個人情報については、漏洩、減失またはき損の防止と是正、その他個人情報の安全管理のために必要かつ適切な措置を講じます。</p>

      </div>

      <Footer/>
    </div>
  );
};

export default TermsPage;