import React from 'react';
import Form from './Form';

function Main() {
  return (
    <div className='wrapper2'>
        <div className="main">
          <div className="kv"><img src="img/main_title.png" alt="" /></div>
        <Form />
        </div>
    </div>
  );
}

export default Main;