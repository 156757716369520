import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/basic_style2.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

const PrivacyPolicyPage = () => {
  useEffect(() => {
    // Yahoo Tag Manager
    const script = document.createElement('script');
    script.src = "https://s.yimg.jp/images/listing/tool/cv/ytag.js";
    script.async = true;
    document.head.appendChild(script);

    window.yjDataLayer = window.yjDataLayer || [];
    function ytag() { window.yjDataLayer.push(arguments); }
    ytag({"type":"ycl_cookie"});

    // Google Tag Manager
    const googleScript = document.createElement('script');
    googleScript.src = "https://www.googletagmanager.com/gtag/js?id=AW-676247113";
    googleScript.async = true;
    document.head.appendChild(googleScript);

    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'AW-676247113');

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(googleScript);
    };
  }, []);

  return (
    <div className="wrap">
      <Header/>
      
      <div id="container">
        <br />
        <h2 style={{textAlign: 'center'}}>個人情報保護方針</h2>

        <p>株式会社N.Eは、職業紹介・派遣事業を実施する上で、お客様の個人情報がプライバシーを構成する重要な情報であることを深く認識し、業務において個人情報を取り扱う場合には、個人情報に関する法令及び個人情報保護のために定めた社内規定を定め、また、組織体制を整備し、個人情報の適切な保護に努めることにより、お客様を尊重し、当社に対する期待と信頼に応えていきます。</p>

        <h3>法令・規範の遵守</h3>
        <p>私たちは、個人情報に関する法令、規範及び社会秩序を遵守し、個人情報の適切な保護に努めます。</p>

        <h3>個人情報の取得、利用、提供</h3>
        <p>私たちは、事業活動の範囲内で個人情報の利用目的を特定し、その目的達成のために必要な限度で公正かつ適正に個人情報の取得、利用及び提供を行います。また、取得した個人情報の目的外利用をしないよう処置を講じます。</p>

        <h3>個人情報の適切な管理</h3>
        <p>私たちは、私たちが取り扱う個人情報について、不正アクセス、紛失、破壊、改ざん、漏えいなどの危険を十分に認識し、合理的な安全対策を実施するとともに、問題が発生した場合は適切な是正措置を講じます。</p>

        <h3>継続的改善</h3>
        <p>私たちは、個人情報保護に関する管理規定及び管理体制を整備し、全社員で徹底して運用するとともに定期的な見直しを行い、継続的な改善に努めます。</p>
        <p>株式会社N.E<br />
        代表取締役　南　広一朗</p>

        <h3>個人情報保護の取り扱いについて</h3>
        <p>株式会社N.E（以下当社）は、その運営に際し個人情報保護の重要性を認識し、社員、スタッフ全員が個人情報に対して十分な配慮を行うと共に大切に保護し、適正な管理を行うことに努めております。</p>

        <h3>個人情報の定義</h3>
        <p>個人情報とは、個人情報の保護に関する法律に規定される生存する個人に関する情報（氏名、生年月日、その他の特定の個人を識別することができる情報）、ならびに特定の個人と結びついて使用されるメールアドレス、その他の個人に関する属性情報であると認識しています。</p>

        <h3>個人情報の利用目的</h3>
        <p>当社における個人情報の利用目的は以下のとおりです。当社は必要な範囲内で皆さまから個人情報をお預かりしています。</p>
        <ul>
          <li>(1)当社による登録者への本サービス提供</li>
          <li>(2)求人案件のご紹介</li>
          <li>(3)登録者の承諾に基づく、本サービス利用企業への個人情報提供</li>
          <li>(4)当社が提供する他のサービスのご案内や資料の送付</li>
          <li>(5)その他、上記業務に関連または付随する業務</li>
        </ul>
        <p>その他、個別に書面で明示した通りの利用目的とします。</p>
        <p>直接書面取得以外で取得する場合の個人情報の利用目的受託した業務の遂行、及びその契約履行のため</p>
        <p>なお、上記の利用目的の達成に必要な範囲内で、当社の関係会社などに提供または委託することがあります。</p>

        <h3>個人情報の適正な取得及び利用</h3>
        <ul>
          <li>1.個人情報の取得は、適法かつ公正な手段により、本人の同意を得た上で行います。</li>
          <li>2.あらかじめその利用目的を明確にし、その目的のために必要な範囲内にとどめます。</li>
          <li>3.個人の利益を侵害する可能性が高い情報は、本人の明確な同意がある場合、または法令等の裏付けがある場合以外は収集しません。</li>
          <li>4.当社が個人情報の処理を伴う業務を外部から受託する場合や外部へ委託する場合は、個人情報に関する秘密の保持、再委託に関する事項、事故時の責任分担、契約終了時の個人情報の返却および消去等について定め、それに従います。</li>
          <li>5.個人情報は、本人の同意を得た範囲内で利用、提供します。</li>
        </ul>

        <h3>個人情報の第三者提供について</h3>
        <p>利用者の個人情報について、利用者本人の同意を得ずに当社サービス利用企業などの第三者に開示することは、原則いたしません。提供先・提供情報内容を特定したうえで、利用者の同意を得た場合に限り開示します。但し法令の範囲内で、利用者の個人情報を提供することがあります。</p>

        <h3>個人情報の委託について</h3>
        <p>当社は利用目的の達成に必要な範囲内において個人情報の取り扱いの全部または一部を委託する場合があります。なお、個人情報の取り扱いを委託する場合は適切な委託先を選定し、個人情報が安全に管理されるよう適切に監督いたします。</p>

        <h3>個人情報提供の任意性</h3>
        <p>当社に対して個人情報を提供することは任意です。ただし、個人情報を提供されない場合には、適切な転職活動サポートサービスを提供できない恐れがあります。</p>

        <h3>法令及びその他の規範について</h3>
        <p>当社は、個人情報の保護に関係する日本の法令及びその他の規範を遵守し、本方針の継続的改善に努めます。</p>

        <h3>個人情報保護に関わる社内活動</h3>
        <p>当社は、個人情報を取り扱う事業所内の職員に対し個人情報の取り扱いに関する教育・指導を年１回実施することとする。また、職業紹介責任者は少なくとも５年に１回は職業紹介責任者講習を受講し個人情報の保護に関する事項等の知識・情報を得るよう努めます。</p>
        <ul>
          <li>・弊社のサイトの利用者数やトラフィックを調査するため</li>
          <li>・弊社のサービスを改善するため</li>
          <li>・セキュリティー保持のため、ご利用から一定の時間が経過したお客様に対してパスワードの再入力（再認証）を促すため</li>
        </ul>
        <p>なお、弊社は、弊社の広告の配信を委託する第三者への委託に基づき、第三者を経由して、弊社のクッキーを保存し、参照する場合があります。</p>

      </div>

      <Footer/>
    </div>
  );
};

export default PrivacyPolicyPage;