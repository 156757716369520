import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/basic_style2.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

const CompanyPage = () => {
  useEffect(() => {
    // Yahoo Tag Manager
    const script = document.createElement('script');
    script.src = "https://s.yimg.jp/images/listing/tool/cv/ytag.js";
    script.async = true;
    document.head.appendChild(script);

    window.yjDataLayer = window.yjDataLayer || [];
    function ytag() { window.yjDataLayer.push(arguments); }
    ytag({"type":"ycl_cookie"});

    // Google Tag Manager
    const googleScript = document.createElement('script');
    googleScript.src = "https://www.googletagmanager.com/gtag/js?id=AW-676247113";
    googleScript.async = true;
    document.head.appendChild(googleScript);

    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'AW-676247113');

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(googleScript);
    };
  }, []);

  return (
    <div className="wrap">
      <Header/>
      
      <div id="container">
        <br />
        <h2 style={{textAlign: 'center'}}>運営会社</h2>
        
        <h3>商号</h3>
        <p>株式会社N.E（英語表記：N.E inc)</p>
        
        <h3>代表者</h3>
        <p>代表取締役　南　広一朗</p>
        
        <h3>所在地</h3>
        <p>〒460-0002 愛知県名古屋市中区丸の内1丁目2-9 長尾ビル2F</p>
        
        <h3>連絡先</h3>
        <p>TEL:052-253-7830 / FAX:052-253-7832</p>
        
        <h3>E-mail</h3>
        <p>info@nine-earth.co.jp</p>
        
        <h3>URL</h3>
        <p>https://nine-earth.com/</p>
        
        <h3>事業内容</h3>
        <ul>
          <li style={{pointerEvents: 'none'}}>許可番号: 派23−303631</li>
          <li>許可番号: 23−ユ−302133</li>
        </ul>
      </div>

      <Footer/>
    </div>
  );
};

export default CompanyPage;