import React from "react";
import Header from "../components/Header";
import Main from "../components/Main";
import Footer from "../components/Footer";

function FormPage() {
  return (
    <div>
        <Header/>
        <Main/>
        <Footer/>
    </div>
  );
}

export default FormPage;
