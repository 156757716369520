import React, { useState, useEffect } from 'react';
import Step from './Step';
import axios from 'axios';
import emailContent from './emailContent';
import adminemailContent from './adminemailContent';

function Form() {
  const [currentStep, setCurrentStep] = useState(0); //現在のステップ
  // 入力データ
  const [formData, setFormData] = useState({
    qualifications: [],
    workStyle: '',
    transferTiming: '',
    prefecture: '',
    address: '',
    name: '',
    birthYear: '',
    phoneNumber: '',
    email: '',
    zip: '',
  });

  // 入力データを変更する
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    if (name === 'qualifications') {
      const newQualifications = formData.qualifications.includes(value)
        ? formData.qualifications.filter((q) => q !== value)
        : [...formData.qualifications, value];
      setFormData({ ...formData, [name]: newQualifications });
    } else {
      setFormData({ ...formData, [name]: newValue });
    }
  };

  // 次ステップへ
  const handleNextStep = () => {
    // console.log(formData)
    setCurrentStep(currentStep + 1);
  };
 
  // 前ステップへ
  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  // リファラー
  const referrer = document.referrer;
  // ホスト名
  const hostname = window.location.hostname;
  // IPアドレス 
  const [ip, setIp] = useState('');

  // IPアドレスの取得
  useEffect(() => {
      axios.get('https://api.ipify.org?format=json')
          .then(response => {
            if (response.data && response.data.ip) {
              setIp(response.data.ip);
            }
          })
          .catch(error => {
              console.error('Error fetching IP address:', error);
          });
  }, []);

  const handleSubmitPHP = async (e) => {
    e.preventDefault();
    console.log(formData);

    axios.post('/send_email.php', {
      userEmail: emailContent({data: formData}),
      adminEmail: adminemailContent({data: formData, ip: ip, host: hostname, url: referrer})
    })
      .then(response => {
        console.log('Email sent successfully');
        // メール送信が成功した場合の処理
      })
      .catch(error => {
        console.log('Failed to send email:', error);
        // メール送信が失敗した場合の処理
      });
      // 求人を探しに行くボタンを押したときの処理
      // thanks.htmlにリダイレクト
      window.location.href = '/thanks';
  };

  return (
    <form id="form">
      <Step
        currentStep={currentStep}
        formData={formData}
        handleInputChange={handleInputChange}
        handleNextStep={handleNextStep}
        handlePrevStep={handlePrevStep}
        handleSubmit={handleSubmitPHP}
        setFormData={setFormData}
      />
    </form>
  );
}

export default Form;