import React from 'react';
import { Link } from 'react-router-dom';
import '../css/style_thanks.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

const ThanksPage = () => {
  React.useEffect(() => {
    // Yahoo Tag Manager
    const script = document.createElement('script');
    script.src = "https://s.yimg.jp/images/listing/tool/cv/ytag.js";
    script.async = true;
    document.head.appendChild(script);

    window.yjDataLayer = window.yjDataLayer || [];
    function ytag() { window.yjDataLayer.push(arguments); }
    ytag({"type":"ycl_cookie"});

    // Google Tag Manager
    const googleScript = document.createElement('script');
    googleScript.src = "https://www.googletagmanager.com/gtag/js?id=AW-676247113";
    googleScript.async = true;
    document.head.appendChild(googleScript);

    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'AW-676247113');
    gtag('event', 'conversion', {'send_to': 'AW-676247113/nKugCJengboBEMnsusIC'});

    // Yahoo Conversion
    ytag({
      "type": "yss_conversion",
      "config": {
        "yahoo_conversion_id": "1001127095",
        "yahoo_conversion_label": "z70wCI3MvtEBELvrsK8C",
        "yahoo_conversion_value": "0"
      }
    });

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(googleScript);
    };
  }, []);

  return (
    <div className="wrap">
      <Header/>

      <div id="container" className="thanks">
        <h2 className="thanks__head">お問い合わせ<br />ありがとうございました</h2>
        <div className="thanks__text">
          お送りいただいた情報は、正常に送信が完了しました。<br />
          ご記入いただいたメールアドレスへ、「お問い合わせ内容控え」のメールを自動送信にてお送りしております。<br />
          お問い合わせ内容のご返信は、追って担当のコンサルタントよりお送りさせていただきます。<br />
          ご質問・ご不明な点や、ご転職をお急ぎの場合は、お電話にてお気軽にお問い合わせくださいませ。<br />
          今後とも介護ナインをよろしくお願いいたします。
        </div>

        <a href="tel:0522537830" className="button__tel--wrap">
          <div className="button_type_tel2">電話で問い合わせる</div>
        </a>
        <p className="tel2_tex">受付時間/8:00～20:00</p>

        <div className="thanks__info">
          <h3>控えのメールが届かない場合は？</h3>
          <p>受信フォルダへメールが届かない場合は、下記のご確認をお願いいたします。</p>
          <ul>
            <li>迷惑メールフォルダを確認する</li>
            <li>ドメイン指定受信（info@nine-earth.co.jp）をする</li>
          </ul>
          <p>万が一メールアドレスの入力に間違いがある場合は、お電話にてご連絡が可能ですのでどうぞご安心ください。</p>
        </div>

        <div className="button__top">
          <Link to="/">トップページへ戻る</Link>
        </div>
      </div>

      <Footer/>
    </div>
  );
};

export default ThanksPage;