import React from 'react';
import '../css/styles.css'

function Header() {
  return (
    <header className='header'>
      <h1>
        <a href="/">
          <img src="img/logo_sp.jpg" width="" alt="" id="start" />
        </a>
      </h1>
    </header>
  );
}

export default Header;