import React from 'react';
import '../css/styles.css'

const today = new Date()
const year = today.getFullYear()

function Footer() {
  return (
    <footer id="footer" className='footer'>
      <ul className="f_character">
        <li>
          <img src="img/character02.png" alt="" width="80px" />
        </li>
      </ul>
      <ul className="f_navi">
        <li>
          <a href="/Terms">利用規約</a>
        </li>
        <li>
          <a href="/Privacy">個人情報保護方針</a>
        </li>
        <li>
          <a href="/Company">運営会社</a>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;