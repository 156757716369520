const today = new Date()
const year = today.getFullYear()
const month = (today.getMonth() + 1).toString().padStart(2, '0')
const date = (today.getDate()).toString().padStart(2, '0')
const day = today.getDay()
const hour = (today.getHours()).toString().padStart(2, '0')
const minute = (today.getMinutes()).toString().padStart(2, '0')
const second = (today.getSeconds()).toString().padStart(2, '0')

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

function emailContent({data}) {

const to = data.email

const subject = "【ご応募・ご登録の御礼】介護ナイン"

const text = `
${data.name}様

お世話になっております。
介護士転職支援をしている介護ナインと申します。

この度は、弊社にご登録くださりありがとうございました。

ご登録者様の就職・転職活動に力添えできれば嬉しく思います。

つきましては、ご状況やご希望を伺う為、担当者携帯電話よりご登録者様へ電話させて頂く可能性もありますが、
ご了承くださいませ。

よろしくお願い致します。

＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝

【 お持ちの資格 】 ${data.qualifications.join(", ")}
【 働き方 】 ${data.workStyle}
【 転職時期 】 ${data.transferTiming}
【 zip 】 ${data.zip}
【 都道府県 】 ${data.prefecture}
【 住所 】 ${data.address}
【 お名前 】 ${data.name}
【 誕生年 】 ${data.birthYear}
【 携帯番号 】 ${data.phoneNumber}
【 Email 】 ${data.email}

＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝

送信日時：${year}/${month}/${date} (${days[day]}) ${hour}:${minute}:${second}

＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
〒460-0002 愛知県名古屋市中区丸の内1丁目2-9 長尾ビル2F
TEL 052-253-7830 / FAX 052-253-7832
mobil 070-3274-2206
株式会社N.E
https://nine-earth.com/
代表取締役　　南　広一朗
紹介: 23－ユ－302133 派遣: 派23－303631
＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
`

    return {
        to: to,
        subject: subject,
        text: text
    }
}

module.exports = emailContent;
  